.bg-wrapper {
    position: relative;
    background-color: rgba(0, 0, 0, 0.357);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.bg-wrapper::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('/playground_assets/WebsiteBG.png') center/cover no-repeat;
    z-index: -1;
}


.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    z-index: 1;
}

.logo img {
    height: 50px;
}


.title {
    text-align: center;
    color: white;
    font-size: 28px;
    font-weight: bold;
    margin: 20px 0;
    z-index: 1;
}


.wallpapers-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 15px;
    padding: 20px;
    border-radius: 20px;
    width: 90%;
    max-width: 1200px;
    z-index: 1;
    margin-bottom: 30px;

}


.wallpaper {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.wallpaper img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 10px;
    transition: transform 0.3s ease;
}


.wallpaper:hover {
    transform: scale(1.02); 
}


.download-btn {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(255, 0, 64, 0.8);
    color: white;
    padding: 8px 12px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 14px;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
}


.wallpaper:hover .download-btn {
    opacity: 1;
    transform: translateX(-50%) translateY(-5px);
}


.download-btn.visible {
    opacity: 1;
    transform: translateX(-50%) translateY(-5px);
}


.download-all-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ff0040;
    color: white;
    padding: 12px 16px;
    border-radius: 8px;
    font-size: 18px;
    text-decoration: none;
    font-weight: bold;
    margin: 20px auto;
    transition: background 0.3s;
}

.download-all-btn:hover {
    background-color: #d90037;
}

.description {
    text-align: center;
    color: white;
    font-size: 16px;
    z-index: 1;
    padding-inline: 20px;
}

.footer{
    background-color: #ff0040;
    padding: 5px 0;
    width: 100%;
    color: white;
    text-align: center;
}


@media (max-width: 768px) {
    .title {
        font-size: 24px;
    }
    
    .wallpapers-grid {
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr)); 
        gap: 10px;
    }
    
    .download-btn {
        font-size: 12px;
        padding: 6px 10px;
    }
}

@media (max-width: 480px) {
    .title {
        font-size: 20px;
    }
    
    .wallpapers-grid {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        gap: 8px;
    }
    
    .download-btn {
        font-size: 11px;
        padding: 5px 8px;
    }
}