.gallery-card1-gallery-card {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.gallery-card1-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 16 / 9; 
}
.gallery-card1-text {
  color: #ffffff;
  margin-top: var(--dl-space-space-twounits);
  font-family: Kanit;
}

.gallery-card1-root-class-name1 {
  height: auto;
}




@media(max-width: 767px) {
  .gallery-card1-gallery-card {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .gallery-card1-image {
    height: 10rem;
  }
}
