.home-container {
  width: 100%;
  display: flex;
  z-index: 100;
  overflow: hidden;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  min-height: 100vh;
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  flex-direction: column;
  background-color: #050514;
}

.home-hero {
  width: 100%;
  height: 100vh;
  display: flex;
  opacity: 1;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.55) 1.00%, rgba(0, 0, 0, 0.55) 100.00%), url("/playground_assets/WebsiteBG.png");  
  background-repeat: no-repeat;
  background-position: top left;
}

.home-background {
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 85%;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-navbar {
  width: 100%;
  display: flex;
  z-index: 1000;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  justify-content: space-between;
}

.home-navlink {
  display: contents;
}

.home-image {
  width: 70px;
  object-fit: cover;
  text-decoration: none;
}

.home-desktop-menu {
  gap: var(--dl-space-space-threeunits);
  display: flex;
}

.home-nav {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.home-link {
  text-decoration: none;
}

.home-link1 {
  text-decoration: none;
}

.home-link2 {
  text-decoration: none;
}

.home-register {
  text-decoration: none;
}

.home-icon {
  width: 24px;
  height: 24px;
}

.home-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}

.home-hero-content {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: 1000px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-text01 {
  font-size: 90px;
  font-style: normal;
  font-family: Kanit;
  font-weight: 600;
  line-height: 1.1;
}

.home-text02 {
  color: #ffffff;
}

.home-text03 {
  color: #de0445;
}

.home-text04 {
  color: #ffffff;
}

.home-caption {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.home-register1 {
  width: 125px;
  height: 121px;
  display: flex;
  flex-direction: row;
  background-size: cover;
  background-color: transparent;
  background-image: url("/playground_assets/Ad Template.png");
  background-position: center;
}

.home-text05 {
  color: rgba(0, 0, 0, 0);
}

.home-caption1 {
  color: rgb(255, 255, 255);
  font-size: 32px;
}

.home-text06 {
  color: #de0445;
  font-family: Kanit;
}

.home-text07 {
  color: #de0445;
  font-family: Kanit;
}

.home-text08 {
  font-family: Kanit;
}

.home-notes {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}

.home-first {
  width: 1522px;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: #050514;
}

.home-about {
  padding-left: 6rem;
  padding-right: 6rem;
}

.home-content {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}

.home-header {
  color: rgb(251, 251, 251);
  font-size: 50px;
  font-style: normal;
  font-family: Kanit;
  font-weight: 600;
  line-height: 60px;
}

.home-text10 {
  color: rgb(251, 251, 251);
  font-size: 18px;
  font-style: normal;
  text-align: justify;
  font-family: Play;
  font-weight: 400;
}

.home-list {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-note {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.team-section-title-1 {
  font-size: 70px;
}

.team-section-title-2 {
  font-size: 40px;
}

.home-point {
  width: 100%;
  height: 1px;
  display: flex;
  max-width: 40px;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: row;
  background-color: var(--dl-color-gray-black);
}

.home-note1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.home-point1 {
  width: 100%;
  height: 1px;
  display: flex;
  max-width: 40px;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: row;
  background-color: var(--dl-color-gray-black);
}

.home-image1 {
  width: 50%;
  object-fit: cover;
  aspect-ratio: 1;
}

.home-watch {
  width: 100vw;
  height: 927px;
  display: flex;
  position: relative;
  transform: rotate();
  align-items: center;
  padding-top: 120px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 120px;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.55) 1.00%, rgba(0, 0, 0, 0.55) 100.00%), url("/playground_assets/WebsiteBG2.png");  
  background-repeat: right;
  background-position: top left, right;
}

.home-title {
  color: rgb(255, 255, 255);
  width: 1576px;
  font-size: 90px;
  font-style: normal;
  text-align: center;
  font-family: Kanit;
  font-weight: 600;
  line-height: 100px;
}

.home-gallery {
  width: 100%;
  display: grid;
  padding: var(--dl-space-space-threeunits);
  grid-gap: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  grid-template-columns: 1fr 1fr 1fr;
}

.home-watch1 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 120px;
  padding-left: 120px;
  padding-right: 120px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.55) 1.00%, rgba(0, 0, 0, 0.55) 100.00%), url("/playground_assets/WebsiteBG.png");  
  background-repeat: left;
  background-position: top left, left;
}

.home-content1 {
  gap: 0 !important;
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: 900px;
  align-items: center;
  flex-direction: column;
}

.home-text23 {
  color: #fdfdfd;
  font-size: 40px;
  text-align: center;
  font-family: Kanit;
  margin-bottom: 0;
}

.home-text24 {
  font-size: 70px;
  text-align: center;
  font-family: Kanit;
}

.home-text25 {
  color: #ffffff;
}

.home-video {
  width: 100%;
  cursor: pointer;
  display: flex;
  position: relative;
  margin-top: 3rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-image2 {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 80px;
  bottom: 0px;
  cursor: pointer;
  margin: auto;
  position: absolute;
  object-fit: cover;
  transition: 0.3s;
}

.home-image2:hover {
  width: 90px;
}

.home-video1 {
  width: 100%;
  transition: 0.3s;
}

.home-video1:hover {
  box-shadow: 0px 0px 30px 0px #000000;
}

.home-footer {
  width: 100%;
  height: 44px;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: column;
  padding-bottom: 0px;
}

.home-copyright {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 14px;
  align-self: center;
  text-align: center;
  line-height: 21px;
  margin-bottom: 0px;
}

@media(max-width: 1440px) {
  .home-text01 {
    width: 1091px;
  }

  .home-text10 {
    color: rgb(251, 251, 251);
    font-size: 18px;
    font-style: normal;
    font-family: Play;
    font-weight: 400;
  }

  .home-text12 {
    color: #fbfbfb;
    font-size: 18px;
    font-style: normal;
    font-family: Play;
    font-weight: 400;
  }

  .home-text15 {
    color: #fbfbfb;
    font-size: 18px;
    font-style: normal;
    font-family: Play;
    font-weight: 400;
  }

  .home-text18 {
    color: #fbfbfb;
    font-size: 18px;
    font-style: normal;
    font-family: Play;
    font-weight: 400;
  }

  .home-text21 {
    color: #fbfbfb;
    font-size: 18px;
    font-style: normal;
    font-family: Play;
    font-weight: 400;
  }

  .home-image1 {
    opacity: 0.65;
  }
}

@media(max-width: 1200px) {
  .home-first {
    width: 100%;
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
  }
}

@media(max-width: 991px) {
  .home-first {
    flex-direction: column;
  }

  .home-content {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }

  .home-image1 {
    width: 100%;
    height: 482px;
    opacity: 0.58;
  }

  .home-watch {
    height: 1188px;
  }

  .home-gallery {
    grid-template-columns: 1fr 1fr;
  }

  .home-copyright {
    font-size: 16px;
  }
}

@media(max-width: 767px) {
  .home-hero {
    height: 60vh;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }

  .home-image {
    width: 57px;
    height: 82px;
  }

  .home-desktop-menu {
    display: none;
  }

  .home-burger-menu {
    display: flex;
  }

  .home-hero-content {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .home-text01 {
    width: 589px;
    font-size: 50px;
  }

  .home-caption {
    gap: var(--dl-space-space-unit);
  }

  .home-register1 {
    padding: var(--dl-space-space-halfunit);
  }

  .home-caption1 {
    font-size: 16px;
    line-height: 24px;
  }

  .home-text06 {
    font-size: 16px;
    line-height: 24px;
  }

  .home-content {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }

  .home-watch {
    height: 916px;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }

  .home-title {
    font-size: 30px;
    line-height: 33px;
  }

  .home-gallery {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .home-watch1 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }

  .home-content1 {
    gap: var(--dl-space-space-oneandhalfunits);
    max-width: 900px;
  }

  .home-image2 {
    width: 40px;
  }

  .home-footer {
    height: 102px;
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-twounits);
  }
}

@media(max-width: 479px) {
  .home-navbar {
    height: 51px;
  }

  .home-text01 {
    width: 339px;
    font-size: 50px;
  }

  .home-register1 {
    width: 47px;
    height: 45px;
  }

  .home-caption1 {
    font-size: 10px;
  }

  .home-header {
    font-size: 30px;
    line-height: 36px;
  }

  .home-list {
    gap: var(--dl-space-space-unit);
  }

  .home-point {
    width: 100%;
    max-width: 20px;
  }

  .home-point1 {
    width: 100%;
    max-width: 20px;
  }

  .home-gallery {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }

  .home-text23 {
    font-size: 20;
  }

  .home-text24 {
    font-size: 40;
  }
}

@media(max-width: 452px) {
  .home-about {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .team-section-title-1 {
    font-size: 30px !important;
    margin-bottom: 0;
  }

  .team-section-title-2 {
    font-size: 20px !important;
    margin-bottom: 0.7rem;
  }

  .home-content1 {
    gap: 0 !important;
  }

  .home-text24 {
    font-size: 30px !important;
  }
}

@media(max-width: 916px) {
  .team-section-title-1 {
    font-size: 50px;
    margin-bottom: 0;
  }

  .team-section-title-2 {
    font-size: 30px;
  }
}